import { Stage_Columns } from '@prisma/client'
import { DefaultOperator, defaultOperators } from 'react-querybuilder'
import { leadTypeOptions } from '../consts/lead'

type ColumnTypeResponse = {
  valueEditorType?: 'select' | undefined | 'checkbox'
  inputType?: 'select' | 'number' | 'string' | 'date' | undefined
  values?: undefined | { name: string; label: string }[]
  operators?: DefaultOperator[]
  defaultValue?: boolean
}
export const getLeadColumnType = (col: Stage_Columns): ColumnTypeResponse => {
  if (['LEAD_SOURCE', 'MERCHANT_PASS_REASON', 'FUNDER_NAME'].includes(col)) {
    return { inputType: 'string' }
  }
  if (
    [
      'CALL_LOGS',
      'PREAPPROVAL_AMOUNT',
      'PRESCRUBBED_REVENUE',
      'DAYS_IN_STAGE',
      'OFFER_AMOUNT',
      'OFFER_TERM_AMOUNT',
      'COMMISSION_POINTS',
      'BUY_RATE'
    ].includes(col)
  ) {
    return { inputType: 'number' }
  }
  if (['LAST_INTERACTION_TYPE', 'PRIORITY', 'TYPE', 'OFFER_TERM_LENGTH'].includes(col)) {
    return {
      inputType: 'select',
      valueEditorType: 'select',
      operators: defaultOperators.filter((op) => op.name === '=' || op.name === '!='),
      values: (col === 'LAST_INTERACTION_TYPE'
        ? ['KIXIE_LOG', 'EMAIL', 'CALL']
        : col === 'TYPE'
        ? leadTypeOptions
        : col === 'OFFER_TERM_LENGTH'
        ? ['DAYS', 'WEEKS', 'MONTHS']
        : ['HIGH', 'MEDIUM', 'LOW']
      ).map((i) => ({
        label: i,
        name: i
      }))
    }
  }
  if (
    [
      'DATE_LAST_INTERACTION',
      'BIRTHDAY',
      'LAST_CALL_LOG',
      'NEXT_TASK_DUE_DATE',
      'DATE_FIRST_INTERACTION',
      'NEWEST_EMAIL',
      'CREATED_AT',
      'DATE_FUNDED',
      'LAST_REASSIGNMENT_DATE'
    ].includes(col)
  ) {
    return { inputType: 'date' }
  }
  if (['HAS_FOLLOWUP', 'BM_WITH_STATEMENTS', 'BM_WITH_APPROVAL', 'PAYMENT_HISTORY'].includes(col)) {
    return { valueEditorType: 'checkbox', operators: defaultOperators.filter((op) => op.name === '='), defaultValue: false }
  }
  return { inputType: 'string' }
}

export const getEditableLeadCols = (isAdmin?: boolean): Partial<Stage_Columns>[] => [
  'AVG_REVENUE',
  'PREAPPROVAL_AMOUNT',
  'MERCHANT_PASS_REASON',
  'BM_WITH_APPROVAL',
  'BM_WITH_STATEMENTS',
  'PAYMENT_HISTORY',
  'MERCHANT_CREDIT',
  'MERCHANT_REQUEST_AMOUNT',
  'TERM_AMOUNT',
  'TERM_LENGTH',
  'NUM_OF_POSITIONS',
  'MISSING_INFO',
  'TYPE',
  'MERCHANT_REQUEST_AMOUNT_IS_MAX',
  'DUPLICATE_LEAD_SOURCE',
  'DUPLICATE_SAME_LEAD_SOURCE',
  ...(isAdmin ? ['LEAD_SOURCE' as Stage_Columns] : [])
]
