import React, { useEffect, useState } from 'react'
import { Box, LinearProgress, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { MoreHorizIcon } from '../../../assets/icons'
import { Dialog, DialogButton, DialogCloseButton, DialogHeader, DialogSheet, DialogTitle } from '../../Dialog'
import { EditField } from './EditField'
import { FileOutput } from '../../../types/procedureOutputs'
import mime from 'mime'
import { toast } from 'react-hot-toast'
import { isExcelFile } from '../../../utils'
import axios from 'axios'
import { trpc } from '../../../core/trpc'
import { useSession } from 'next-auth/react'
import { FileViewerMenu, FileViewerMenuActions } from './FileViewerMenu'
import { downloadFileFromLink } from '../../../models/file'
import bytesToSize from '../../../utils/bytesToSize'
import CheckCircle from '../../../icons/CheckCircle'

const CHROME_FILE_VIEWER_BG = '#525659'

type FileViewerProps = {
  /**
   *  file *must* be defined when open is true
   */
  file?: FileOutput
  refetch: () => void

  open: boolean
  onClose: () => void

  hasPrevFile: boolean
  onPrevFile: () => void
  hasNextFile: boolean
  onNextFile: () => void
}

const MONTHS_LIST = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const FILE_TYPES = ['Application', 'Bank Statement', 'AR Report', 'Tax Return', 'Credit Card Statement']

const defaultFilePreviewState = Object.freeze({
  progress: 0,
  blob: null,
  error: null
})

const FileViewerContents = ({ file, refetch, onClose, onPrevFile, hasPrevFile, onNextFile, hasNextFile }: FileViewerProps) => {
  const viewFilePreference = useSession().data?.user.file_view_preference

  const [optionsAnchorEl, setOptionsAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedFileType, setSelectedFileType] = useState<string>('')
  const [selectedMonth, setSelectedMonth] = useState<string>('')
  const [isEditingFilename, setIsEditingFilename] = useState(false)
  const [filename, setFilename] = useState<string>(file!.name ?? '')
  const [isEditingDescription, setIsEditingDescription] = useState(false)
  const [description, setDescription] = useState<string>(file?.description ?? '')

  const inferredFileType = FILE_TYPES.find((type) => filename.toLowerCase().includes(type.toLowerCase()))
  const inferredMonth = MONTHS_LIST.find((month) => filename.toLowerCase().includes(month.toLowerCase()))

  const [filePreview, setFilePreview] = useState<{
    progress: number
    blob: string | null
    error: string | null
  }>(defaultFilePreviewState)

  // reset the page
  useEffect(() => {
    if (!file) return

    setOptionsAnchorEl(null)
    setSelectedFileType('')
    setSelectedMonth('')
    setIsEditingFilename(false)
    setIsEditingDescription(false)
    setDescription(file.description ?? '')
    setFilename(file.name)
  }, [file])

  useEffect(() => {
    if (!selectedFileType && !selectedMonth) {
      return
    }

    const name = [selectedFileType, selectedMonth].filter(Boolean).join(' - ')
    const ext = mime.getExtension(file!.type)
    setFilename(`${name}.${ext}`)
  }, [selectedFileType, selectedMonth, file])

  const handleSelectFileType = (e: SelectChangeEvent<string>) => {
    setIsEditingFilename(true)
    setSelectedFileType(e.target.value)
  }

  const handleSelectMonth = (e: SelectChangeEvent<string>) => {
    setIsEditingFilename(true)
    setSelectedMonth(e.target.value)
  }

  const handleCancelEditFilename = () => {
    setIsEditingFilename(false)

    setSelectedFileType('')
    setSelectedMonth('')

    // reset
    setFilename(filename)
  }

  const handleSaveEditFilename = async () => {
    updateFileMutation.mutate({ id: file!.id, name: filename })
    setSelectedFileType('')
    setSelectedMonth('')
    setIsEditingFilename(false)
  }

  const getViewFileLink = trpc.file.viewFileLink.useMutation()
  const updateFileMutation = trpc.file.updateFile.useMutation({
    onSuccess: () => {
      refetch()
      toast.success('Successfully updated file')
    },
    onError: () => toast.error('File update failed')
  })

  const deleteFileMutation = trpc.file.deleteFile.useMutation({
    onSuccess: () => {
      refetch()
      onClose()
      toast.success('Successfully deleted file')
    },
    onError: () => toast.error('File deletion failed')
  })

  // load file on mount
  const loadFile = async () => {
    if (!file) {
      return
    }

    if (!mime.getType(file.type) && !mime.getExtension(file.type)) {
      toast.error('Unable to preview file')
      return
    }

    const type = mime.getExtension(file.type) ? file.type : (mime.getType(file.type) as string)
    if (isExcelFile(file)) {
      return
    }

    const cancelTokenSource = axios.CancelToken.source()

    try {
      setFilePreview(defaultFilePreviewState)

      const url = await getViewFileLink.mutateAsync({ id: file.id })
      const response = await axios.get<ArrayBuffer>(url, {
        cancelToken: cancelTokenSource.token,
        responseType: 'arraybuffer',
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setFilePreview((p) => ({ ...p, progress }))
          }
        }
      })

      const objectUrl = URL.createObjectURL(new Blob([response.data], { type }))
      if (viewFilePreference === 'NEW_TAB') {
        const el = document.createElement('a')
        el.href = objectUrl
        el.hidden = true
        el.target = '_blank'
        el.click()
        el.remove()
      } else {
        setFilePreview((p) => ({
          ...p,
          blob: objectUrl,
          error: null
        }))
      }
    } catch (e) {
      toast.error(`Failed to get file. ${e}`)
    }

    return () => {
      cancelTokenSource.cancel('abort due to component unmount')

      setFilePreview(defaultFilePreviewState)
    }
  }

  useEffect(() => {
    loadFile()
  }, [file?.id])

  const handleMenuAction = async (action: FileViewerMenuActions) => {
    switch (action) {
      case FileViewerMenuActions.Download:
        try {
          const url = (await getViewFileLink.mutateAsync({ id: file!.id })) as unknown as string
          await downloadFileFromLink(file!, url)
        } catch (e) {
          toast.error(`Failed to download file. ${e}`)
        }
        break
      case FileViewerMenuActions.MarkAsContract:
        await updateFileMutation.mutateAsync({ id: file!.id, is_contract: !file!.is_contract })
        break

      case FileViewerMenuActions.Delete:
        await deleteFileMutation.mutateAsync({ id: file!.id })
        break
    }
  }

  return (
    <Box className="flex t:flex-row h-full">
      <Box className="flex flex-grow border-none h-full" style={{ backgroundColor: CHROME_FILE_VIEWER_BG }}>
        {filePreview.progress !== 100 && (
          <Box className="w-full flex items-center justify-center">
            <LinearProgress variant="determinate" value={filePreview.progress} color="inherit" className="w-[400px] text-white" />
          </Box>
        )}
        {filePreview.error && <Box>{filePreview.error}</Box>}
        {filePreview.blob && <iframe className="flex flex-grow border-none h-full" title={file!.name} src={filePreview.blob} />}
      </Box>

      <Box className="flex flex-col flex-grow max-w-[480px]">
        <DialogHeader
          leadingAccessory={
            <DialogTitle
              title={
                <>
                  {file!.is_contract && (
                    <Tooltip title="Contract File">
                      <CheckCircle color="success" fontSize="small" />
                    </Tooltip>
                  )}
                  {file!.name}
                </>
              }
            />
          }
          trailingAccessory={
            <>
              <DialogButton onClick={onPrevFile} disabled={!hasPrevFile} Icon={ArrowBackIcon} />
              <DialogButton onClick={onNextFile} disabled={!hasNextFile} Icon={ArrowForwardIcon} />
              <DialogButton onClick={(e) => setOptionsAnchorEl(e.currentTarget)} Icon={MoreHorizIcon} />

              <DialogCloseButton onClick={onClose} />
              <FileViewerMenu file={file!} onClose={() => setOptionsAnchorEl(null)} onAction={handleMenuAction} anchorElement={optionsAnchorEl} />
            </>
          }
        />

        <Box className="p-5 pt-0">
          <Box className="flex flex-row gap-4 mb-4">
            <Select
              value={selectedFileType ?? inferredFileType}
              onChange={handleSelectFileType}
              fullWidth
              size="small"
              renderValue={(v) => v ?? 'Choose a file type'}
              displayEmpty
            >
              {FILE_TYPES.map((opt) => (
                <MenuItem onClick={(e) => e.stopPropagation()} value={opt} key={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={selectedMonth ?? inferredMonth}
              onChange={handleSelectMonth}
              fullWidth
              size="small"
              renderValue={(v) => v ?? 'Choose a month'}
              displayEmpty
            >
              {MONTHS_LIST.map((month) => (
                <MenuItem onClick={(e) => e.stopPropagation()} value={month} key={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <EditField
            label="Filename"
            value={file!.name}
            isEditMode={isEditingFilename}
            editValue={filename}
            onEditValueChange={(val) => setFilename(val)}
            onEditMode={() => setIsEditingFilename(true)}
            onCancelEdit={handleCancelEditFilename}
            onSaveEdit={handleSaveEditFilename}
          />

          <EditField
            label="Description"
            placeholder="Enter a description"
            value={file!.description ?? ''}
            isEditMode={isEditingDescription}
            onEditMode={() => setIsEditingDescription(true)}
            editValue={description}
            onEditValueChange={(val) => setDescription(val)}
            onSaveEdit={() => {
              updateFileMutation.mutate({ id: file!.id, description })
              setIsEditingDescription(false)
            }}
            onCancelEdit={() => setIsEditingDescription(false)}
          />

          {file?.size && <EditField label="File Size" value={bytesToSize(file!.size)} disabled />}
        </Box>

        <Box className="flex flex-grow bg-grey-100 border-0 border-t border-grey-300 border-solid">{/* placeholder for AI/Copilot */}</Box>
      </Box>
    </Box>
  )
}

export const FileViewer = (props: FileViewerProps) => {
  const { open, onClose } = props

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogSheet className="h-full min-w-[340px] t:w-[80vw] max-w-[1440px]">
        <FileViewerContents {...props} />
      </DialogSheet>
    </Dialog>
  )
}
